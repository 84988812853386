import {Typography, Tooltip, MenuItem} from '@mui/material';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import {Link} from 'react-router-dom';

import DisplayManageCustomerAccount from 'src/components/DisplayManageCustomerAccount';
import { getTiersDisplay } from 'src/utilities/getTiersDisplay';


function MenuItemList(id){
  return(
    <MenuItem component={Link} to={`/${id}/customer-account-details`} sx={{p: 0, minWidth: '30px'}} aria-controls={'edit-field'}>Manage Customer Account</MenuItem>
  );
}

const sortDate = (date1,date2) => new Date(date1) - new Date(date2);

const columns = [
  { 
    field: 'edit', 
    headerName: 'Actions', 
    flex: 1,
    sortable: false,
    cellClassName: 'cat-level',
    renderCell: (params) => { 
      return <div className="wrap-cell edit">
        <DisplayManageCustomerAccount MenuItemList={() => MenuItemList(params.id)}/></div>;
    } 
  },
  { 
    field: 'name', 
    headerName: 'Company Name', 
    flex: 4,
    sortable: true,
    sortingOrder: ['desc','asc'],
    cellClassName: 'cat-level',
    renderCell: (params) => {
      return <div className="wrap-cell company-name">
        <Link to={`/${params.id}/customer-account-details`} sx={{p: 0, minWidth: '30px', mt: 2}}>
        <Typography color="primary.dark" variant="subtitle2">{params.value}</Typography>
        </Link></div>;
    }
  },
  { 
    field: 'dateAdded', 
    headerName: 'Date Created', 
    flex: 2,
    sortable: true,
    sortComparator: sortDate,
    sortingOrder: ['desc','asc'],
    cellClassName: 'cat-level',
    renderCell: (params) => {
      return <div className="wrap-cell">
        {params.value}
        </div>;
    } 
  },
  { 
    field: 'accessToStage', 
    headerName: 'Environment', 
    flex: 2,
    sortable: true,
    sortingOrder: ['desc','asc'],
    cellClassName: 'cat-level',
    renderCell: (params) => {
      return <div className="wrap-cell account">
        {(params.row.version === 'V2') && <>Production{params.value && <Tooltip sx={{ ml: 1 }} title={'Access to Stage Environment: Yes'} arrow><EmojiFlagsIcon/></Tooltip>}</>} 
        </div>;
    } 
  },
  {
    field: 'tiers', 
    headerName: 'LENS Tier', 
    flex: 2,
    sortable: false,
    cellClassName: 'cat-level',
    renderCell: (params) => {
      return <div className="wrap-cell account">
        {getTiersDisplay(params.value)}
        </div>;
    } 
  },
  { 
    field: 'status', 
    headerName: 'Product Stage', 
    flex: 2,
    sortable: true,
    sortingOrder: ['desc','asc'],
    cellClassName: 'cat-level',
    renderCell: (params) => {
      return <div className="wrap-cell account">
        {params.value}
        </div>;
    } 
  },
];

export default columns;