import {useState} from 'react';
import {useQuery} from 'src/hooks';
import {Link, useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Button, Divider, Grid, Paper, Typography} from '@mui/material';

import {getEstimatedClaimsReports} from 'src/api';
import ExpandableTable from './ExpandableTable';
import columns from './columns';


function EstimatedClaimsTable({setIsDownloadError}) {
  const {carrierId} = useParams();

  const [params, setParams] = useState({
    limit: 5,
    next: '',
    previous: ''
  });

  const estimatedClaimsResponse = useQuery(['reports', carrierId, params], () => getEstimatedClaimsReports(carrierId, params.limit, params.previous, params.next), {retry: false});

  return (
    <Paper>
      <Grid container sx={{justifyContent: 'space-between', alignItems: 'center'}}>
        <Grid item sx={{p: 2}}>
          <Typography variant='subtitle1'>All Generated Reports</Typography>
        </Grid>
        <Grid item sx={{p: 2}}>
          <Button variant='outlined' LinkComponent={Link} to='request-report' disabled={estimatedClaimsResponse?.isLoading || estimatedClaimsResponse?.isError}>Request a Report</Button>
        </Grid>
      </Grid>
      <Divider sx={{opacity: 0.4}}/>
      {!estimatedClaimsResponse?.isLoading && estimatedClaimsResponse?.data?.result?.length === 0 ?
        <Typography p={4}>No reports to display.</Typography>
        :
        <ExpandableTable
          queryResponse={estimatedClaimsResponse}
          limit={params?.limit}
          setQueryParams={setParams}
          columns={columns(carrierId, setIsDownloadError)}
        />
      }
    </Paper>
  );
}

EstimatedClaimsTable.propTypes = {
  setIsDownloadError: PropTypes.func
};

export default EstimatedClaimsTable;
