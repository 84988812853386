import {Chip, Typography} from '@mui/material';


const matchesColumns = [
  { field: 'fileName',
    headerName: 'File Name',
    flex: 1.5,
    renderCell: (params) => {
      return (
        <>
          <Typography variant='caption'>{params?.value}</Typography>
          {params?.row?.status !== 'Skipped' &&
            <>
              <br/>
              <Typography variant='caption' color='text.secondary'>{params?.row?.incognito ? '(Matches Private to Evadata)' : '(Customer Facing Matches)'}</Typography>
            </>
          }
        </>
      );
    }
  },
  {
    field: 'id',
    headerName: 'Dataload ID',
    flex: 1,
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params?.value}</p></div>;
    },
  },
  {
    field: 'submitted',
    headerName: 'Processing Completed',
    flex: 1,
    headerAlign: 'left',
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params?.value}</p></div>;
    },
  },
  {
    field: 'completed',
    headerName: 'Results Sent',
    flex: 1,
    headerAlign: 'left',
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params?.value}</p></div>;
    },
  },
  {
    field: 'totalMatches', 
    headerName: 'Match Records', 
    flex: 1,
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params?.value}</p></div>;
    },
  },
  {
    field: 'fileStatus',
    headerName: 'File Status',
    flex: 1,
    headerAlign: 'left',
    renderCell: (params) => {
      const colors  = {
        'Processing': 'info',
        'Failed': 'error',
        'Errors Found': 'warning',
        'Successful': 'success',
        'Skipped': 'default'
      };

      const color = colors[params?.value] || 'default';
      return params?.value && <Chip size="small" color={color} className={params?.value} label={params?.value} variant="outlined" />;
    },
  },
  {
    field: 'downloadFile',
    headerName: 'Download',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    filterable: false,
    hideable: false,
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params) => {
      if (params?.value) {
        return params?.value;
      }
    },
  }
];

export default matchesColumns;