import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Divider, Grid, Box } from '@mui/material';
import DisplayMoreDetails from './displayMoreDetails';
import DisplayMoreDetailsMatchResults from './displayMoreDetailsMatchResults';
import DisplayMoreDetailsCustomerDataLoads from './displayMoreDetailsCustomerDataLoads';


function CollapsibleRow({ row, columns, openCalendarDrawer, setHolidayCalendarId }) {

  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ width: '20px', borderBottom: 'unset' }}>
          {row?.fileType === 'customer-files' && (row?.fileStatus === 'Failed' || row?.fileStatus === 'Processing') ?
            <></>
            :
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        </TableCell>
        {columns?.map((column) => (
          <TableCell key={column?.field} align="left" sx={{ borderBottom: 'unset' }}>{column?.renderCell({value: row[column?.field], row})}</TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns?.length + 1}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Grid container sx={{ p: 2, pt:0 }}>
                {row?.fileType === 'customer-files' &&
                  <Grid item xs={12}>
                    <DisplayMoreDetailsCustomerDataLoads title='Record Overview' totalRecords={row?.totalRecords} numOfErrors={row?.numOfErrors} missingStats={row?.missingStats} />
                  </Grid>
                }
                {row?.configurationType === 'matches' &&
                  <>
                    <Grid item xs={5}>
                      <DisplayMoreDetails title='Customer List Details' encryptionEnabled={row?.custEncryptionEnabled} auditDate={row?.custAuditDate} auditUser={row?.custAuditUser} frequency={row?.custFrequency} method={row?.custTransferMethod} emails={row?.custNotificationEmails} />
                    </Grid>
                    <Grid item xs={1}><Divider sx={{ height: '115%', mt: '-4px' }} orientation="vertical" variant="middle" display="flex" /></Grid>
                    <Grid item xs={5}>
                      <DisplayMoreDetails title='Match Results Details' encryptionEnabled={row?.matchEncryptionEnabled} auditDate={row?.matchAuditDate} auditUser={row?.matchAuditUser} frequency={row?.matchFrequency} reportDates={row?.matchReportDates} frequencyDay={row?.matchFrequencyDay} method={row?.matchTransferMethod} holidayCalendarId={row?.matchHolidayCalendarId} annualDmf={row?.annualDmf} emails={row?.matchNotificationEmails} openCalendarDrawer={openCalendarDrawer} setHolidayCalendarId={setHolidayCalendarId} />
                    </Grid>
                  </>
                }
                {row?.configurationType === 'death_records' &&
                  <Grid item xs={5}>
                    <DisplayMoreDetails title='Verified Deaths List Details' encryptionEnabled={row?.deathEncryptionEnabled} auditDate={row?.deathAuditDate} auditUser={row?.deathAuditUser} frequency={row?.deathFrequency} method={row?.deathTransferMethod} emails={row?.deathNotificationEmails} />
                  </Grid>
                }
                {
                  row?.variant === 'match results table' &&
                  <Grid item xs={12}>
                    {row?.status === 'Skipped' ?
                      <Typography variant='body2'>{row?.note}.</Typography>
                      :
                      <DisplayMoreDetailsMatchResults title='Match Records Overview:' newMatches={row?.newMatches} deletedMatches={row?.deletedMatches} otherMatches={row?.otherMatches} />
                    }
                  </Grid>
                }
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

CollapsibleRow.propTypes = {
  row: PropTypes.object,
  columns: PropTypes.array,
  openCalendarDrawer: PropTypes.bool,
  setHolidayCalendarId: PropTypes.func
}

export default CollapsibleRow;