import React from 'react';

import {OktaAuth, toRelativeUrl} from '@okta/okta-auth-js';
import {Security} from '@okta/okta-react';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ErrorBoundary} from 'react-error-boundary';

import ErrorScreen from 'src/components/ErrorScreen';
import './App.css';
import okta from './config/okta';
import AppRoutes from './AppRoutes';
import ScrollToTop from './components/ScrollToTop';


function App() {
  const oktaAuth = new OktaAuth(okta.oidc);
  const queryClient = new QueryClient();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <div className="App">
          <ErrorBoundary FallbackComponent={ErrorScreen}>
            <CssBaseline />
            <Grid>
              <ScrollToTop />
              <AppRoutes />
            </Grid>
          </ErrorBoundary>
        </div>
      </Security>
    </QueryClientProvider>
  );
}

export default App;
