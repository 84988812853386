import {Outlet, useSearchParams} from 'react-router-dom';
import {useQuery} from 'src/hooks';

import {getAllCarrierConfigurations} from 'src/api';


function AccountsContext() {
  const [searchParams] = useSearchParams();
  const accountsResponse = useQuery(['allCarrierConfigurations', searchParams.get('accountSuccess'), searchParams.get('companyName')], () => getAllCarrierConfigurations(), {retry: false});
  
  return (<Outlet context={{accountsResponse}}/>);
}

export default AccountsContext;
