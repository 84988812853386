import { Routes, Route } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';

import CarrierContext from 'src/contexts/carrierContext';
import AccountsContext from 'src/contexts/accountsContext';
import CustomerAccounts from 'src/pages/CustomerAccounts';
import AddCustomerAccount from 'src/pages/AddCustomerAccount';
import CustomerAccountDetails from 'src/pages/Customer Account Details';
import CustomerAccountFileActivity from 'src/pages/Customer Account File Activity';
import CustomerAccountUsers from 'src/pages/Customer Account Users';
import CustomerAccountAddUser from 'src/pages/Customer Account Users/Customer Account Add User';
import CustomerAccountEditUser from 'src/pages/Customer Account Users/Customer Account Edit User';
import CustomerAccountSetup from 'src/pages/Customer Account Setup';
import EvadataLoading from 'src/components/EvadataLoading';
import ActiveListSummary from 'src/pages/ActiveListSummary';
import ActivityLog from 'src/pages/ActivityLog';
import EstimatedClaims from 'src/pages/EstimatedClaims';
import RequestReport from 'src/pages/EstimatedClaims/requestReport';
import ErrorScreen from 'src/components/ErrorScreen';
import { RequiredAuth } from './RequiredAuth';
import UnauthorizedErrorScreen from './unauthorizedErrorScreen';


const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<RequiredAuth />}>
        <Route element={<AccountsContext/>}>
          <Route path='/' element={<CustomerAccounts />}/>
          <Route path='add-customer-account' element={<AddCustomerAccount />} />
          <Route path=':carrierId' element={<CarrierContext/>}>
            <Route path='active-list-summary' element={<ActiveListSummary />} />
            <Route path='activity-log' element={<ActivityLog />} />
            <Route path='customer-account-details' element={<CustomerAccountDetails />} />
            <Route path='customer-account-setup' element={<CustomerAccountSetup />} />
            <Route path='customer-account-file-activity' element={<CustomerAccountFileActivity />} />
            <Route path='customer-account-users' element={<CustomerAccountUsers />} />
            <Route path='customer-account-add-user' element={<CustomerAccountAddUser />} />
            <Route path='customer-account-edit-user' element={<CustomerAccountEditUser />} />
            <Route path='estimated-claims' element={<EstimatedClaims/>} />
            <Route path='estimated-claims/request-report' element={<RequestReport/>} />
          </Route>
        </Route>
      </Route>
      <Route path="/login/callback" element={<LoginCallback loadingElement={<EvadataLoading />} errorComponent={UnauthorizedErrorScreen} />} />
      <Route path='*' element={<ErrorScreen variant='404'/>} />
      <Route path='401' element={<ErrorScreen variant='401'/>} />
      <Route path='500' element={<ErrorScreen/>} />
    </Routes>
  );
}

export default AppRoutes;