import {Typography, Box} from '@mui/material';
import {UnpublishedRounded, CheckCircleRounded} from '@mui/icons-material';

import CategoryChips from 'src/components/CategoryChips';


const customerListColumns = [
  { 
    field: 'customerList', 
    headerName: 'Customer List', 
    flex:0.6,
    renderCell: (params) => {
      return (
        <>
          {params?.row?.custNickname && <Typography variant='body2' sx={{whiteSpace: 'nowrap'}}>{params?.row?.custNickname}</Typography>}
          <Typography variant='caption'>{params.value}</Typography>
        </>
      );
    }
  },
  {
    field: 'matchWorkflow',
    headerName: 'Match Workflow',
    flex: 0.6,
    renderCell: (params) => {
      const fileTypes = params?.row?.matchFileType === 'both' ? ['json', 'xlsx'] : [params?.row?.matchFileType];

      return (
        <>
          {params?.row?.matchNickname && <Typography variant='body2' sx={{whiteSpace: 'nowrap'}}>{params?.row?.matchNickname}</Typography>}
          {fileTypes?.map((fileType) =>
            <>
              <Typography key={fileType} variant='caption'>{params?.row.matchFilePrefix}.{fileType}</Typography>
              <br/>
            </>
          )}
        </>
      );
    },
  },
  {
    field: 'sources',
    headerName: 'Sources',
    flex: 0.6,
    headerAlign: 'left',
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params.value}</p></div>;
    },
  },
  { 
    field: 'matchCategories', 
    headerName: 'Match Categories',
    flex: 1.2,
    renderCell: (params) => {
      return <CategoryChips categories={params.value}/>;
    }
  },
  {
    field: 'disabled',
    headerName: 'Match File Status',
    renderCell: (params) => {
      return (
        <Box sx={{whiteSpace: 'nowrap', mr: 6}}>
          <Typography variant='caption'>
            {params?.value ?
              <>
                <UnpublishedRounded color='error' sx={{float: 'left', mt: '-1px', mr: '5px', width: '16px'}}/> Deactivated
              </>
              :
              <>
                <CheckCircleRounded color='success' sx={{float: 'left', mt: '-1px', mr: '5px', width: '16px'}}/> Activated
              </>
            }
          </Typography>
        </Box>
      );
    }
  }
];

export default customerListColumns;
