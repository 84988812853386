import {Outlet, useParams, useOutletContext} from 'react-router-dom';
import {useQuery} from 'src/hooks';

import {getCarrierConfiguration} from 'src/api';


function CarrierContext() {
  const {carrierId} = useParams();
  const {accountsResponse} = useOutletContext();

  const carrierConfigResponse = useQuery(['getCarrierConfig', carrierId], () => getCarrierConfiguration(carrierId), {retry: false});

  return (<Outlet context={{accountsResponse, carrierConfigResponse}}/>);
}

export default CarrierContext;
