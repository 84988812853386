import { Chip } from '@mui/material';
import ErrorFileDownload from '../ErrorFileDownload';


const fileColumns = (carrierId) => [
  { 
    field: 'fileName', 
    headerName: 'File Name', 
    flex:1.4,
    renderCell: (params) => {
      if (params?.row?.nickname != null) {
        return <div className="wrap-cell"><p>{params.row.nickname}<br />{params?.value}</p></div>;
      }
      return <div className="wrap-cell"><p>{params?.value}</p></div>;
    }
  },
  {
    field: 'id',
    headerName: 'Dataload ID',
    flex: 1,
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params?.value}</p></div>;
    },
  },
  {
    field: 'submitted',
    headerName: 'Submitted',
    flex: 1,
    headerAlign: 'left',
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params?.value}</p></div>;
    },
  },
  { 
    field: 'completed', 
    headerName: 'Completed',
    flex: 1,
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params?.value}</p></div>;
    } 
  },
  {
    field: 'totalRecords', 
    headerName: 'Total Records', 
    flex: 0.8,
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params?.value}</p></div>;
    } 
  },
  {
    field: 'fileStatus',
    headerName: 'File Status',
    flex: 1.2,
    headerAlign: 'left',
    renderCell: (params) => {
      const colors  = {
        'Processing': 'info',
        'Failed': 'error',
        'Errors Found': 'warning',
        'Successful': 'success'
      };
      
      const color = colors[params?.value] || '';
      return params?.value && <Chip size="small" color={color} className={params?.value} label={params?.value} variant="outlined" />;
    },
  },
  { 
    field: 'numOfErrors', 
    headerName: '# of Errors',
    flex: 0.8,
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params?.value}</p></div>;
    } 
  },
  {
    field: 'downloadFile',
    headerName: 'Download',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: (params) => {
      const {row} = params;
      const displayDownload = parseInt(row?.numOfErrors) > 0 || row?.fileStatus === 'Failed';

      if(displayDownload) {
        return <ErrorFileDownload fileType={row?.fileType} dataLoadId={row?.id} carrierId={carrierId}/>;
      }
      return <></>
    },
  }
];

export default fileColumns;