import React from 'react';
import { Skeleton, Box } from '@mui/material';

const SkeletonRow = () => (
  <Box sx={{width: {xs: "200%", sm: '120%', md: '100%'} }}>
    <Skeleton variant="rectangular" sx={{ mt: '24px', mb: 2, ml: 1, mr: 2, width: "5%", float: 'left' }} />
    <Skeleton variant="rectangular" sx={{ mt: '24px', mb: 2, mr: 2, width: '30%', float: 'left' }} /> 
    <Skeleton variant="rectangular" sx={{ mt: '24px', mb: 2, mr: 2, width:'14%', float: 'left' }} /> 
    <Skeleton variant="rectangular" sx={{ mt: '24px', mb: 2, mr: 2, width: '14%', float: 'left' }} /> 
    <Skeleton variant="rectangular" sx={{ mt: '24px', mb: 2, mr: 2, width: '14%', float: 'left' }} /> 
    <Skeleton variant="rectangular" sx={{ mt: '24px', mb: 2, mr: 2, width: "13%", float: 'left' }} />
  </Box>
);

function SkeletonRowCustomerAccounts() {

  return (
    <>
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
    </>
  )
}

export default SkeletonRowCustomerAccounts;